<template lang="pug">
div
  // STATISTICS CARD
  .vx-row
    .vx-col.w-full(class="sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3")
      statistics-card-line.mb-base(hideChart="", icon="ShoppingCartIcon", icon-right="", :statistic="formatPrice(rentalTotal)", statisticTitle="ค่าเช่าทั้งหมด", color="success")
    .vx-col.w-full(class="sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3")
      statistics-card-line.mb-base(hideChart="", icon="AwardIcon", icon-right="", :statistic="formatPrice(bailTotal)", statisticTitle="เงินประกันทั้งหมด")
    .vx-col.w-full(class="sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3")
      statistics-card-line.mb-base(hideChart="", icon="CornerUpLeftIcon", icon-right="", :statistic="formatPrice(returnTotal)", statisticTitle="คืนเงินประกันทั้งหมด", color="danger")
    .vx-col.w-full( class="sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3")
      statistics-card-line.mb-base(hideChart="", icon="ShoppingBagIcon", icon-right="", :statistic="formatPrice(saleTotal)", statisticTitle="ขายสินค้าทั้งหมด", color="warning")
  vx-card(style="overflow-x: scroll;")
    vs-row(vs-w="12")
      //- MAIN COLUMN
      //- วันที่ลงรายการ
      vs-col(vs-type="flex", vs-justify="center", vs-align="center", vs-lg="3", vs-sm="3", vs-xs="3" style="z-index: 9999 !important;")
        .grid-layout-container.alignment-block.pr-2(style="vertical-align:text-top ;width:100%;")
          vs-row(vs-w="12")
            vs-col.py-2(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="12", vs-sm="12", vs-xs="12")
              | วันที่ลงรายการ
          vs-row(vs-w="12")
            vs-col(vs-type="flex", vs-justify="center", vs-align="center", vs-lg="12", vs-sm="12", vs-xs="12")
              .grid-layout-container.alignment-block.pr-2(style="vertical-align:text-top ;width:100%;")
                vs-row
                  vs-col(vs-type="flex", vs-justify="center", vs-align="center", vs-lg="12", vs-sm="12", vs-xs="12")
                    v-date-picker(style="width:100%;font-size:14px;", :locale="{\
                    id: 'th',\
                    firstDayOfWeek: 2,\
                    masks: { L: 'DD/MM/YYYY' }\
                    }", mode="range", v-model="calendarData")
      //- MAIN COLUMN
      //- คำค้นหา
      vs-col(vs-type="flex", vs-justify="center", vs-align="center", vs-lg="3", vs-sm="3", vs-xs="3")
        .grid-layout-container.alignment-block(style="vertical-align:text-top ;width:100%;")
          vs-row(vs-w="12")
            vs-col.py-2(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="12", vs-sm="12", vs-xs="12")
              | คำค้นหา
          vs-row(vs-w="12")
            vs-col(vs-type="flex", vs-justify="center", vs-align="center", vs-lg="12", vs-sm="12", vs-xs="12")
              .grid-layout-container.alignment-block(style="vertical-align:text-top ;width:100%;")
                vs-row
                  vs-col(vs-type="flex", vs-justify="center", vs-align="center", vs-lg="12", vs-sm="12", vs-xs="12")
                    vs-input.w-full(v-model="searchText", style="border-radius:20px;", placehoder="ชื่อหรือรหัสสินค้า", @keyup.enter="startSearch()")

      //- MAIN COLUMN
      //- ปุ่มค้นหาและreset
      vs-col(vs-type="flex", vs-justify="center", vs-align="center", vs-lg="5", vs-sm="5", vs-xs="5")
        vs-row(vs-w="12")
          vs-col.px-2.pt-10(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="4", vs-sm="4", vs-xs="4")
            vs-button(@click="startSearch()", style="width:100%;", color="primary", type="filled") ค้นหา
          vs-col.px-2.pt-10(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="4", vs-sm="4", vs-xs="4")
            vs-button(@click="resetAll()", style="width:100%;", color="warning", type="filled") reset
          //- vs-col.px-2.pt-10(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="4", vs-sm="4", vs-xs="4")
          //-   vs-button(@click="resetAll()", style="width:100%;", color="success", type="filled") Export Excel

          //- *** Export Excel
          vs-col(vs-type="flex", vs-justify="center", vs-align="flex-end", vs-lg="4", vs-sm="4", vs-xs="4", style="padding-bottom: 5px;" )
            img(src="/icons8-export-excel-48.png", alt="alt", style=" cursor:pointer; width: 32px;  height: 32px;", @click="exportExcel()")
            vue-excel-xlsx.btn.py-3( id="excelBtn", ref="excelButton", style='padding: 0 ;width: 1px !important; height: 1px !important; border:0 ; background-color: white;',  :data='theData', :columns='columns', :file-name="exportedFilename()", :file-type="'xlsx'", :sheet-name="'sheet1'")

    // BRANCHES
    // SEARCH TEXT
    //- MAIN ROW
    vs-row.row-form.pb-2(vs-type="flex", vs-justify="flex-start", vs-w="12")
      //- MAIN COLUMN
      //- ประเภท
      vs-col.form-label(vs-type="flex ", vs-justify="flex-start", vs-align="center", vs-lg="5", vs-sm="5", vs-xs="12")
        .grid-layout-container.alignment-block(style="vertical-align:text-top ;width:100%;")
          vs-row(vs-w="12")
            vs-col.py-2(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="12", vs-sm="12", vs-xs="12")
              | ประเภท
          vs-row(vs-w="12")
            vs-col(vs-type="flex", vs-justify="center", vs-align="center", vs-lg="12", vs-sm="12", vs-xs="12")
              .grid-layout-container.alignment-block(style="vertical-align:text-top ;width:100%;")
                vs-row
                  vs-col(vs-type="flex", vs-justify="center", vs-align="center", vs-lg="12", vs-sm="12", vs-xs="12")
                    multiselect(
                      style="height:20px !important"
                      v-model="category_value",
                      :options="categories",
                      :multiple="true",
                      :group-select="true",
                      :close-on-select="false",
                      placeholder="พิมพ์เพื่อค้นหา",
                      selectLabel="เลือก",
                      deselectLabel="ลบ"
                      selectedLabel="เลือกแล้ว"

                    )
                      span(slot="noResult").
                        ไม่พบข้อมูล

      //- MAIN COLUMN
      //- ว่าง
      vs-col.form-label(vs-type="flex ", vs-justify="flex-start", vs-align="center", vs-lg="6", vs-sm="6", vs-xs="12")



      //- MAIN COLUMN
      //- vs-col.form-label(vs-type="flex ", vs-justify="flex-end", vs-align="center", vs-lg="1", vs-sm="1", vs-xs="1")

      //- MAIN COLUMN
      //- หน้า page
      vs-col.form-label(vs-type="flex ", vs-justify="flex-end", vs-align="center", vs-lg="1", vs-sm="1", vs-xs="2")
        .grid-layout-container.alignment-block(style="vertical-align:text-top ;width:100%;")
          vs-row(vs-w="12")
            vs-col.py-2(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="12", vs-sm="12", vs-xs="12")
              | หน้า {{currentx}}
          vs-row(vs-w="12")
            vs-col(vs-type="flex", vs-justify="center", vs-align="center", vs-lg="12", vs-sm="12", vs-xs="12")
              .grid-layout-container.alignment-block(style="vertical-align:text-top ;width:100%;")
                vs-row
                  vs-col(vs-type="flex", vs-justify="center", vs-align="center", vs-lg="12", vs-sm="12", vs-xs="12")
                    v-select(style="width: 100%;", v-model="pageitemSelected", :options="pageitemOptions", :select="pageitemSelected")

    //- SEARCH TEXT
    //- PAGINATION
    vs-row(vs-type="flex", vs-justify="flex-end", vs-w="12")
      vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="12", vs-sm="12", vs-xs="12")
        vs-pagination(style="display: block !important;", :total="pageNumber", v-model="currentx", goto="")
    //- TABLE
    vs-table(:sst="true", strip="", style="z-index:1; min-width: 950px; ", @change-page="handleChangePage",  :data="orders")
      template(style="z-index:1 !important;", slot="thead")
        //- vs-th(sort-key="updateDateTime") Select
        vs-th(sort-key="createDateTime") วันเดือนปี
        vs-th(sort-key="orderNumber") เลข Order
        vs-th(sort-key="customerName") ผู้เช่า
        vs-th(sort-key="updateBy") ผู้รับเงิน
        vs-th(sort-key="statusTo") ประเภท
        vs-th(sort-key="returnMethod") วิธีชำระ
        vs-th(sort-key="returnAmount") ยอดรวม

      template(style="z-index:1 !important;", slot-scope="{data}")
        vs-tr(:data="tr", :key="indextr", v-for="(tr, indextr) in data")
          
          //- vs-td
          //-   feather-icon(:data-id='tr.productItemId', v-if='data[indextr].selected===true', @click='addSelectedList(tr, $event)', icon='CheckSquareIcon', svgclasses='stroke-current text-primary')
          //-   feather-icon(:data-id='tr.productItemId', v-else='', @click='addSelectedList(tr, $event)', icon='SquareIcon', svgclasses='stroke-current')
          vs-td(style="min-width:120px;") {{formatDate(tr.createDateTime)}}
          //- vs-td.mytable( @click="openTabto(tr.orderHeaderId)") {{tr.orderNumber}}
          vs-td.mytable 
            span( v-if="tr.statusTo==='ขายสินค้า'"  ) {{tr.orderNumber}} 
            span( v-else  @click="openTabTo(tr.orderHeaderId)" style="color:#0069FF;") {{tr.orderNumber}} 
            br
            |  ({{getBranchName(tr.branchId)}})
          //- <vs-td style="">{{tr.updateBy}}</vs-td>
          vs-td(style="") {{tr.customerName}}
          vs-td(style="padding-left:20px; ")
            | {{tr.updateBy}}

          vs-td.mytable.max-w-100(v-if="tr.statusTo === 'เงินประกัน'", style="padding-left:20px;  ")
            .blue-round-btn(style="padding:5px 10px; width: 100px;") {{tr.statusTo}}
          vs-td.mytable.max-w-100(v-else-if="tr.statusTo === 'ค่าเช่า'", style="padding-left:20px;  ")
            .green-round-btn(style="padding:5px 10px; width: 100px;") {{tr.statusTo}}
          vs-td.mytable.max-w-100(v-else-if="tr.statusTo === 'ขายสินค้า'", style="padding-left:20px;  ")
            .yellow-round-btn(style="padding:5px 10px; width: 100px;") {{tr.statusTo}}
          vs-td.mytable.max-w-100(v-else="", style="padding-left:20px;  ")
            .red-round-btn(style="padding:5px 10px; width: 100px;") {{tr.statusTo}}
          vs-td.mytable(style="padding-left:20px; ") {{tr.paymentMethod}}
          vs-td.mytable(style="padding-left:20px; ") {{formatPrice(tr.amount)}}
          div(style="width: 100%; background-color:pink; height:50px; ")
    // TABLE

</template>

<script>
import Multiselect from "vue-multiselect";
import Prism from "vue-prism-component";
import Datepicker from "vuejs-datepicker";
// import HotelDatePicker from "vue-hotel-datepicker";
import vSelect from "vue-select";
import CurrencyValue from "./Components/CurrencyValue";
import StatisticsCardLine from "@/views/wintershopback/Components/StatisticsCardLine";
import shapeFormat from "../../mixins/shapeFormat.js";

import Vue from "vue";
// window.addEventListener("load", function() {
//   var x = document.getElementsByClassName("tr-expand ");
//   for (var ii = 0; ii < x.length; ii++) {
//     x[ii].innerHTML = "12";
//   }
// });
export default {
  mixins: [shapeFormat],
  data() {
    return {
      searchText: "",
      selectedCheck: [],
      columns: [
        {
          label: "วัน/เดือน/ปี",
          field: "createDateTime",
          dataFormat: this.format_ddmmyyy
        },
        {
          label: "เลข Order",
          field: "orderNumber"
        },
        {
          label: "สาขา",
          field: "branchName"
        },
        {
          label: "ผู้เช่า",
          field: "customerName"
        },
        {
          label: "ผู้รับเงิน",
          field: "createBy"
        },
        {
          label: "ประเภท",
          field: "statusTo"
        },
        {
          label: "วิธีชำระ",
          field: "paymentMethod"
        },
        {
          label: "ยอดรวม",
          field: "amount"
        }
      ],

      theData: [],
      currentx: 1,
      pageNumber: 1,
      pageitemSelected: 20,
      pageitemOptions: [10, 20, 30, 50, 100, 200, 500],
      calendarData: {},
      searchDate: null,
      startNull: null,
      endNull: null,
      searchDateEnd: null,
      orders: [],
      startingDateValue: new Date(),
      endingDateValue: new Date(),
      theCheckIn: new Date(),
      theCheckOut: null,
      expenseAmount: 0,
      expenseComment: "",
      incomeAmount: 0,
      incomeComment: "",
      incomeUpdateDate: "",
      expenseUpdateDate: "",
      incomeSelected: "เงินรายเดือน",
      incomeOptions: ["เงินรายเดือน", "ส่งของ", "ยอดยกมา"],
      expenseSelected: "ค่าไลน์แมน",
      expenseOptions: [
        "ค่าไลน์แมน",
        "ค่าซ่อมแซมร้าน สาขาเมืองทอง",
        "ค่าซ่อมซิป",
        "ค่ากับข้าว",
        "ค่่าน้ำ"
      ],
      expensePanelActive: false,
      incomePanelActive: false,
      thai: {
        "day-names": ["อ.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."],
        "check-in": "วันเริ่ม",
        "check-out": "วันสุดท้าย",
        "month-names": [
          "มกราคม",
          "กุมภาพันธ์",
          "มีนาคม",
          "เมษายน",
          "พฤษภาคม",
          "มิถุนายน",
          "กรกฎาคม",
          "สิงหาคม",
          "กันยายน",
          "ตุลาคม",
          "พฤศจิกายน",
          "ธันวาคม"
        ]
      },
      categories: ["ค่าเช่า", "เงินประกัน", "คืนเงินประกัน", "ขายสินค้า"],
      category_value: ["ค่าเช่า", "เงินประกัน", "คืนเงินประกัน", "ขายสินค้า"],
      category_str: "",
      categoryCheckbox: ["ค่าเช่า", "เงินประกัน", "คืนเงินประกัน"],
      branchCheckbox: ["ลาดพร้าว", "เมืองทองธานี"],
      branches: ["ลาดพร้าว", "เมืองทองธานี"],

      statusToReturn: "รับชุดแล้ว",
      statusToReturnOption: [
        { text: "รับชุดแล้ว", value: "รับชุดแล้ว" },
        { text: "ยกเลิก", value: "ยกเลิก" }
      ],
      popupActive: false,

      selectedList: [],
      selectAll: false,
      selectStatus: 0, // {0: ไม่เลือกเลย  1: เลือกทั้งหมด 2: เลือกบางอัน}
      fromSelectAll: false,
      date: null,
      pickupDate: null,

      payment_select: "เงินสด",
      payment_option: [
        { text: "เงินสด", value: "เงินสด" },
        { text: "โอนเงิน", value: "โอนเงิน" },
        { text: "Alipay/wechat", value: "Alipay/wechat" },
        { text: "บัตรเครดิต", value: "บัตรเครดิต" }
      ],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ],
      originalList: [],
      currentUser: "",
      thisBranch: {},
      today: new Date(),
      lastdate: new Date(),
      rentalTotal: 0,
      bailTotal: 0,
      returnTotal: 0,
      saleTotal: 0
    };
  },

  async created() {
    // INITIALIZE DATA
    await this.$store.dispatch("shop/fetch");
    await this.init();
  },

  async mounted() {
    this.currentUser = JSON.parse(localStorage.getItem("username"));
    // this.thisBranch = await this.loadBranchShare(
    //   this.$store.state.auth.mybranchid
    // );
    this.today = this.formatDate(this.today);
    // console.log("this.today >>> ", this.today);
    this.lastdate = this.formatDate(this.getdate(this.today, -30));
    // console.log("this.lastdate >>> ", this.lastdate);

    // var inp = document.getElementsByClassName("datepicker__input");
    // inp[0].innerHTML = "วันเริ่ม";
    // inp[1].innerHTML = "วันสุดท้าย";
    this.theCheckIn = null;
    this.theCheckOut = null;

    // console.log("thelist >>>> ", thelist);
  },
  computed: {
    //  *** BEGIN : 2020-11-28 : Search By branches
    getCurrentBranch() {
      return this.$store.getters["auth/currentBranch"];
    },
    getMyBranches() {
      // console.log('@@@@PICKUP myBranches >>> ',this.$store.getters["auth/myBranches"]);
      return this.$store.getters["auth/myBranches"];
    },
    myPermission() {
      // console.log('@@@@PICKUP myPermission >>> ',this.$store.getters["auth/permission"]);

      return this.$store.getters["auth/permission"];
    },
    //  *** BEGIN : 2020-11-28 : Search By branches

    //  *** BEGIN : 2020-04-06 : TAX INFO
    taxInfo() {
      return this.$store.getters["shop/tax"];
    },
    logappSelected() {
      return this.$store.getters["shop/logappSelected"];
    }
  },
  methods: {

    openTabTo(orderid){
      window.open("/backoffice/order-detail/" + orderid, "_blank");
    },
    exportExcel() {
      // this.$refs.excelButton.click();

      this.theData = [];

      this.orders.forEach(x => {
        this.theData.push(x);
      });
      Vue.nextTick(() => {

        // this.theData;
        var getid = document.getElementById("excelBtn");
        // console.log("getid >>> ", getid);
        getid.click();
        // console.log("Try to export excel  orders >>> ", this.orders.length);
      });
    },
    exportedFilename() {
      var rightnow = new Date();
      var today_format = this.formatDateTime(rightnow);

      var excleName = "Alex_Monetary_" + today_format;
      // console.log("today_format ", today_format);
      return excleName;
    },
    handleChangePage() {
      //  console.log("The user changed the page ");
    },
    async init() {

      this.searchDate = null;
      this.searchDateEnd = null;
      this.calendarData = {};
      this.searchText = "";
      this.currentx = 1;

      await this.newload();
      // var res = null;

      // var item = {
      //   "category": "payment",
      //   "branchId" : this.getCurrentBranch.branchId
      // };

      // try {
      //   res = await this.$http.post(
      //     this.$store.state.apiURL + "/api/accounting/monetaryflow" ,
      //     item,
      //     this.$store.state.auth.apiHeader
      //   );
      // this.orders =  res.data;
      // this.originalList =  res.data;
      // } catch (error) {
      //     this.orders =  [];
      //     this.originalList =  [];
      // }
    },
    getBranchName(id) {
      if (this.getMyBranches.length <= 0) {
        return "ไม่ระบุ";
      }
      // console.log("this.branches >>> ", this.branches);
      var b = this.getMyBranches.find(x => x.branchId === id);
      if (b === null || b === undefined) {
        return "ไม่ระบุ";
      } else {
        return b.branchName;
      }
    },

    async newload() {
      // this.btnActive = !this.btnActive;
      // this.$vs.loading({ type: "radius" });


        //   // RELOAD STOCK

        // RELOAD ITEMS

        var req = {
          T1: this.currentx,
          T2: this.pageitemSelected,
          T3: this.searchText,
          T4: this.getCurrentBranch.branchId,
          T7: this.category_str
        };

        var startNull = false;
        var endNull = false;

        if (
          this.searchDate === "" ||
          this.searchDate === null ||
          this.searchDate === undefined
        ) {
          startNull = true;
        }

        if (
          this.searchDateEnd === "" ||
          this.searchDateEnd === null ||
          this.searchDateEnd === undefined
        ) {
          endNull = true;
        }

        //  *** BEGIN : 2020-12-09 : Search By branches

        let thecalen = Object.assign({} ,this.calendarData)

        if (
          Object.entries(thecalen).length === 0
        ) {
          startNull = true;
          endNull = true;
        }

        if (startNull === false && endNull === false) {
          req.T5 = this.searchDate;
          req.T6 = this.searchDateEnd;
        }

        //  *** BEGIN : 2020-12-09 : Search By branches
          // LOAD ITEM
      try {
        var response = null;
        this.$http.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

        response = await this.$http.post(
          this.$store.state.apiURL + "/api/accounting/monetaryflow/pagination",
          req,
          this.$store.state.auth.apiHeader
        );

        this.pageNumber = response.data.pageNumber;

        // console.log("req >>> ",req );
      } catch (error) {
        response = null;
        this.$vs.loading.close();
      }

      if (response === null) {
        setTimeout(() => {
          // this.noticeError("ไม่สำเร็จ");

          this.$vs.loading.close();
        }, 200);
        this.btnActive = !this.btnActive;
      } else {
        //  console.log("ALREADY 200");
        this.orders = response.data.itemList;
        this.originalList = response.data.itemList;
        // if (this.$store.state.auth.permission === "staff") {
        //   this.itemsInStock = this.removeZero(response.data);
        //   this.originalList = this.removeZero(response.data);
        // }

        this.$vs.loading.close();

        // this.noticeSuccess("บันทึกเสร็จสิ้น");

        // this.btnActive = !this.btnActive;
      }

      // this.checkSelected();
    },

    async reload() {
      // this.orders = this.originalList;
      var res = null;

      var item = {
        category: "payment",
        branchId: this.getCurrentBranch.branchId
      };

      try {
        res = await this.$http.post(
          this.$store.state.apiURL + "/api/accounting/monetaryflow",
          item,
          this.$store.state.auth.apiHeader
        );
        this.orders = res.data;
        this.originalList = res.data;
      } catch (error) {
        this.orders = [];
        this.originalList = [];
        // this.noticeError(error);
      }
    },
    async startSearch() {
      // set page  = 1 every time that start to search
      this.currentx = 1;
      await this.newload();
    },
    // async startSearch() {
    // await this.reload();
    // if (this.$store.state.auth.permission === "staff") {
    //   this.orders = this.orders.filter(
    //     x =>
    //       x.receiveDate >= this.theCheckIn &&
    //       x.receiveDate >= this.theCheckOut
    //   );
    // } else {
    //   this.filterAll();
    // }
    // },
    resetBtn() {
      this.orders = this.originalList;
      this.calendarData = {};
    },
    searchBtn() {
      var list = [];
      this.orders = [];
      var searchDate = this.formatDate(this.searchDate);
      var searchDateEnd = this.formatDate(this.searchDateEnd);

      var startNull = false;
      var endNull = false;

      if (
        this.searchDate === "" ||
        this.searchDate === null ||
        this.searchDate === undefined
      ) {
        startNull = true;
      }

      if (
        this.searchDateEnd === "" ||
        this.searchDateEnd === null ||
        this.searchDateEnd === undefined
      ) {
        endNull = true;
      }

      //  *** BEGIN : 2020-11-28 : Search By branches
      let thecalen = Object.assign({} ,this.calendarData)
      if (
        Object.entries(thecalen).length === 0
      ) {
        startNull = true;
        endNull = true;
      }
      //  *** BEGIN : 2020-11-28 : Search By branches

      var searchList = [];

      if (startNull && endNull) {
        searchList = this.originalList;
        console.log("BOTH NULL");
      } else {
        list = this.originalList;
        // var tmplist = [];
        searchList = list.filter(
          s =>
            this.formatDate(s.updateDateTime) >= searchDate &&
            this.formatDate(s.updateDateTime) <= searchDateEnd
        );
        console.log("NOT NULL");
      }

      //  console.log("searchList >>> ",searchList);

      for (let ind = 0; ind < searchList.length; ind++) {
        const ee = searchList[ind];
        this.orders.push(ee);
      }
    },
    searchByDate(list, startDate, endDate, filedName) {
      var templist = [];
      for (var i = 0; i < list.length; i++) {
        var listdate = new Date(list[i][filedName]);
        var ckin = new Date(startDate);
        var ckout = new Date(endDate);
        // console.log(
        //   "listdate >>> ",
        //   listdate,
        //   "  ckin >>> ",
        //   ckin,
        //   "  ckout >>> ",
        //   ckout
        // );

        if (listdate >= ckin && listdate <= ckout) {
          templist.push(list[i]);
          // console.log("TRUE ");
        }
      }
      // console.log("searchByDate", templist);

      return templist;
    },
    filterMore(
      list /* Array of object list */,
      filtering /* Array single list */,
      filedName
    ) {
      var templist = [];
      if (filtering.length >= 1) {
        for (var i = 0; i < list.length; i++) {
          for (var j = 0; j < filtering.length; j++) {
            if (list[i][filedName] == filtering[j]) {
              templist.push(list[i]);
            }
          }
        }
      }
      return templist;
    },
    filterAll() {
      this.orders = this.filterMore(
        this.originalList,
        this.branchCheckbox,
        "branch"
      );
      this.orders = this.filterMore(
        this.orders,
        this.categoryCheckbox,
        "category"
      );
      // console.log(
      //   "TRUE theCheckIn  >>> ",
      //   this.isDate(this.searchDate),
      //   " theCheckOut",
      //   this.isDate(this.searchDateEnd)
      // );

      // var searchDate = this.formatDate(this.searchDate);
      // var searchDateEnd = this.formatDate(this.searchDateEnd);

      // if (this.isDate(searchDate) && this.isDate(searchDateEnd)) {
      this.orders = this.searchByDate(
        this.orders,
        this.searchDate,
        this.searchDateEnd,
        "receiveDate"
      );
      //   console.log("TRUE CHECK IN >>>");
      // }
    },
    async resetAll() {
      // this.orders = this.originalList;
      this.searchDate = null;
      this.searchDateEnd = null;
      this.calendarData = {};
      this.searchText = "";
      this.currentx = 1;

      this.category_value = [
        "ค่าเช่า",
        "เงินประกัน",
        "คืนเงินประกัน",
        "ขายสินค้า"
      ];
      this.category_str = "ค่าเช่า|เงินประกัน|คืนเงินประกัน|ขายสินค้า";

      // var inp = document.getElementsByClassName("datepicker__input");
      // inp[0].innerHTML = "วันเริ่ม";
      // inp[1].innerHTML = "วันสุดท้าย";
      await this.newload();
      // console.log("inp[0] >>> ", inp[0].innerHTML);

      // console.log("inp[1] >>> ", inp[1].innerHTML);
    },
    // newCheckIn: function(newDate) {
    //   console.log("A new check in >>> ", newDate);
    //   this.theCheckIn = newDate;
    // },
    // newCheckOut: function(newDate) {
    //   console.log("A new check out >>> ", newDate);
    //   this.theCheckOut = newDate;
    // },

    incomePanel(event) {
      event.stopPropagation();
      this.incomePanelActive = true;
    },
    expensePanel(event) {
      event.stopPropagation();
      this.expensePanelActive = true;
    },
    formatPrice(value) {
      // let val = (value / 1).toFixed(2).replace(",", ".");
      // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    changeToReturn: function(event) {
      event.stopPropagation();
      this.popupActive = true;
    },
    selectAllOrders: function(event) {
      event.stopPropagation();

      var ind = 0;

      if (this.selectAll === false && this.selectStatus === 1) {
        this.selectAll = true;
      }
      if (this.selectStatus === 0 || this.selectStatus === 2) {
        // console.log("expected >>> 0 or 2");
        this.selectedList = [];
        for (ind = 0; ind < this.orders.length; ind++) {
          this.orders[ind].selected = true;
          this.selectedList.push(this.orders[ind]);
        }
        this.selectStatus = 1;
      } else if (this.selectStatus === 1) {
        // console.log("expected >>> 1");
        for (ind = 0; ind < this.orders.length; ind++) {
          this.orders[ind].selected = false;
        }
        this.selectedList = [];
        this.selectStatus = 0;
      }
      this.fromSelectAll = true;
      // console.log(
      //   "SelectAll ==> " + this.selectAll + " STATUS  ===> " + this.selectStatus
      // );
      // console.log(this.orders);
      // console.log(this.selectedList);
    },
    addSelectedList(indextt, event) {
      event.stopPropagation();
      // console.log(this.orders[indextt].selected);

      if (!this.orders[indextt].selected) {
        this.orders[indextt].selected = true;
        this.selectedList.push(this.orders[indextt]);
        // console.log("ADDED");
      } else {
        if (this.selectedList) {
          this.orders[indextt].selected = false;
          var foundIndextt = this.selectedList.findIndex(
            x => x.orderNumber === this.orders[indextt].orderNumber
          );
          this.selectedList.splice(foundIndextt, 1);
          // console.log("REMOVED");
        }
      }
      this.orders[indextt].selected = !this.orders[indextt].selected;
      // console.log(
      //   "SelectAll ==> " +
      //     this.selectAll +
      //     "TRUE STATUS  ===> " +
      //     this.selectStatus
      // );
      // console.log(this.orders);
      // console.log(this.selectedList);
    }
  },

  // created: function() {
  //   this.originalList = this.orders;
  // },

  components: {
    Multiselect,
    Prism,
    Datepicker,
    // HotelDatePicker,
    "v-select": vSelect,
    CurrencyValue,
    StatisticsCardLine
  },
  watch: {
    category_value() {
      if (this.category_value.length > 0) {
        this.category_str = this.category_value.join("|");
      } else {
        this.category_str = "";
      }
    },
    async currentx() {
      await this.newload();
    },
    async pageitemSelected() {
      await this.newload();
    },
    orders: {
      handler() {
        // console.log('CHANGE >>>> ');

        this.rentalTotal = 0;
        this.bailTotal = 0;
        this.returnTotal = 0;
        this.saleTotal = 0;

        for (var i = 0; i < this.orders.length; i++) {
          if (this.orders[i].statusTo === "ค่าเช่า") {
            this.rentalTotal =
              this.convertToNum(this.rentalTotal) +
              this.convertToNum(this.orders[i].amount);
          }

          if (this.orders[i].statusTo === "เงินประกัน") {
            this.bailTotal =
              this.convertToNum(this.bailTotal) +
              this.convertToNum(this.orders[i].amount);
          }

          if (this.orders[i].statusTo === "คืนเงินประกัน") {
            this.returnTotal =
              this.convertToNum(this.returnTotal) +
              this.convertToNum(this.orders[i].amount);
          }
          if (this.orders[i].statusTo === "ขายสินค้า") {
            this.saleTotal =
              this.convertToNum(this.saleTotal) +
              this.convertToNum(this.orders[i].amount);
          }
        }
      },
      deep: true
    },
    async getCurrentBranch() {
      await this.newload();
    },
    calendarData: {
      handler(value) {
        console.log("calendarData value >>>" , value)
        this.searchDate = this.formatDate(value.start);
        this.searchDateEnd = this.formatDate(value.end);
        console.log("calendarData value start >>>" ,  this.searchDate)
        console.log("calendarData value end >>>" ,  this.searchDateEnd)
      },
      deep: true
    },
    selectedList: function() {
      var ordersLength = this.orders.length;
      var selectedLength = this.selectedList.length;

      if (ordersLength == undefined) {
        this.selectStatus = 0;
        if (this.fromSelectAll === false) this.selectAll = false;
        // console.log("IN ordersLength == undefined");

        // console.log("SelectAll");
      }
      if (selectedLength == undefined) {
        this.selectStatus = 0;
        if (this.fromSelectAll === false) this.selectAll = false;
        // console.log("IN selectedLength == undefined)");
      }

      if (ordersLength == selectedLength && selectedLength > 0) {
        this.selectStatus = 1;
        if (this.fromSelectAll === false) this.selectAll = true;
        // console.log("IN ordersLength == selectedLength && selectedLength > 0");
        // console.log("SelectAll");
      } else {
        if (selectedLength == 0 || selectedLength == undefined) {
          this.selectStatus = 0;
          if (this.fromSelectAll === false) this.selectAll = false;
          // console.log("IN selectedLength == 0 || selectedLength == undefined");
        } else {
          this.selectStatus = 2;
          if (this.fromSelectAll === false) this.selectAll = true;
          // console.log("IN ELSE");
          // console.log("Select some");
        }
      }
      // console.log(this.orders);
      this.fromSelectAll = false;
      // console.log(
      //   "WACTCH selectedList >>> this.selectAll : " +
      //     this.selectAll +
      //     "  >>> this.selectStatus :  " +
      //     this.selectStatus
      // );

      // console.log(this.orders);
      // console.log(this.selectedList);
    },
    branchCheckbox: function() {
      this.filterAll();
    },
    categoryCheckbox: function() {
      this.filterAll();
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.vs__selected-options .vs__search{
  display: none ;
}
.vs-con-tbody {
  z-index: 1;
}
input {
  width: 100%;
}
td {
  font-size: 12px;
  padding-left: 20px;
}
.td-check {
  display: none;
}
.vs-select--input {
  font-size: 12px;
}
.input-select {
  font-size: 12px !important;
}

.iconhover:hover {
  background-color: #d0f3ff;
}

.vs-select--options span {
  font-size: 12px !important;
  width: 120%;
}
.changetoreturn {
  background: #f5a623;
  border: 1px solid #ad6c02;
  border-radius: 16px;
  font-family: mitr;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}

/* .vs-table--header
{
  display : none  !important;
} */

/************* VUE HOTEL DATEPICKUP **************/

.datepicker__input {
  font-size: 12px !important;
  line-height: 4 !important;
  padding: 4px 25px 2px !important;
}

.con-vs-popup .vs-popup {
  width: 750px;
}

.con-vs-popup .vs-popup .datepicker {
  left: -285px;
  padding: 0 10px;
}

.datepicker.datepicker--closed {
  display: none;
}
.con-vs-popup .vs-popup .datepicker__inner {
  padding: 0;
  float: left;
  padding-bottom: 5px;
}

.con-vs-popup .vs-popup .datepicker__month-day {
  visibility: visible;
  text-align: center;
  margin: 0;
  border: 0;
  height: 25px;
  padding-top: 5px !important;
  vertical-align: middle;
}

.con-vs-popup .vs-popup .datepicker__week-row {
  border-bottom: 5px solid #fff;
  height: 20px;
}

/* VUE HOTEL  */

.red-round-btn {
  background: #e95455;
  border: 1px solid #9e0619;
  border-radius: 16px;
  font-family: mitr;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  padding: 7px 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.green-round-btn {
  background: #7ed321;
  border: 1px solid #549c04;
  border-radius: 16px;
  font-family: mitr;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  padding: 7px 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.blue-round-btn {
  background: #4a90e2;
  border: 1px solid #2372ce;
  border-radius: 16px;
  font-family: mitr;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  padding: 7px 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.yellow-round-btn {
  background: #f5a623;
  border: 1px solid #ad6c02;
  border-radius: 16px;
  font-family: mitr;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  padding: 7px 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.income-panel header {
  background-color: #7ed321;
  color: white !important;
}
.income-panel header {
  background-color: #7ed321;
  color: white !important;
}

.expense-panel header {
  background-color: #e95455;
  color: white !important;
}

.dropdown-toggle .clear {
  display: none;
}

.mytable {
  padding-left: 20px;
  font-family: "mitr";
  font-weight: bold;
}

.max-w-100 {
  max-width: 100px;
}

.datepicker__tooltip {
  display: none !important;
}

.button-shadow {
  -webkit-box-shadow: 3px 3px 11px 0px rgba(196, 196, 196, 1);
  -moz-box-shadow: 3px 3px 11px 0px rgba(196, 196, 196, 1);
  box-shadow: 3px 3px 11px 0px rgba(196, 196, 196, 1);
}
/* .vs__search{

} */
</style>
